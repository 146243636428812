import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import { PostCard } from "../components/Lib"
import {
  toTitleCase,
  transformEpisodesData,
  transformPostsData,
} from "../utils/helpers"
import SEO from "../components/seo"
import {
  ActionBar,
  Button,
  Header,
  Container,
  Section,
  Footer,
  Icon,
  EpisodeCard,
  Heading,
  Tag,
} from "../components/Lib"
import {
  toJalaliDate,
  toPersianNumber,
  createFullName,
  slugify,
} from "../utils/helpers"

const BlogPost = props => {
  const episodes = transformEpisodesData(props.data.relatedEpisodes.edges)
  const posts = transformPostsData(props.data.relatedPosts.edges)
  const post = props.data.strapiPost
  const relatedEpisodes = props.data.relatedEpisodes.edges
  let relatedPosts = props.data.relatedPosts.edges
  const postHeader = useRef()
  useEffect(() => {
    const links = document.querySelectorAll(".article__content a")
    const images = document.querySelectorAll(".article__content img")
    links.forEach(link => {
      link.classList.add("link")
    })
    images.forEach(image => {
      image.parentElement.style.textAlign = "center"
      const p = image.parentElement
      p.innerHTML = ""
      const figure = document.createElement("figure")
      figure.classList.add("article__content__figure")
      const figcaption = document.createElement("figcaption")
      const figcaptionText = document.createTextNode(
        image.getAttribute("alt") ? image.getAttribute("alt") : " "
      )
      figcaption.appendChild(figcaptionText)
      figure.appendChild(image)
      figure.appendChild(figcaption)
      p.appendChild(figure)
    })
  }, [])
  return (
    <Container className="blog_post">
      <SEO
        title={post?.title}
        image={{
          src: post?.mainImage?.childImageSharp.fixed.src,
          width: post?.mainImage?.childImageSharp.fixed.width,
          height: post?.mainImage?.childImageSharp.fixed.height,
        }}
        description={post?.description}
        keywords={post?.keyword?.map(item => item.title)}
      />
      <Header className="header--lightgray" />
      <main className="article">
        <header ref={postHeader} className="article__header">
          <div className="article__header__wrapper">
            <div className="article__category">{post?.category?.name}</div>
            <h1 className="article__title">{post?.title}</h1>
            <section className="article__info">
              <div>
                <Icon name="calendar" />
                {toJalaliDate(new Date(post?.created_at))}
              </div>
              <div>
                <Icon name="feather" />
                {createFullName(post?.author?.firstName, post?.author?.lastName)}
              </div>
              <div>
                <Icon name="clock" />
                {`${toPersianNumber(post?.fields?.readingTime)}  دقیقه`}
              </div>
            </section>
            <figure className="article__figure">
              <Img
                fluid={post?.mainImage?.childImageSharp.fluid}
                className="article__image"
                alt={post?.title}
              />
            </figure>
          </div>
        </header>
        <article className="article__content">
          <ReactMarkdown
            source={post?.content}
            transformImageUri={uri =>
              uri.startsWith("http")
                ? uri
                : `${process.env.IMAGE_BASE_URL}${uri}`
            }
          />
        </article>
        {post?.resources && (
          <div className="article__resources">
            <h4>{post?.resources?.length > 1 ? "منابع" : "منبع"}</h4>
            <ul>
              {post?.resources?.map(resource => (
                <li>
                  <a
                    href={resource.url}
                    target="_blank"
                    className="fancy_link english_font"
                  >
                    {toTitleCase(resource.title)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <Section backgroundColor="var(--lightgray)">
          <Heading color="gray" title="مطالعه بیشتر..." icon="file_plus" />
          <div className="article__more">
            <div className="article__more__box">
              {episodes.length >= 0 && (
                <>
                  {episodes.map(episode => (
                    <EpisodeCard
                      className="episode-card"
                      tagComponent={
                        <Tag color="black" children="اپیزود مرتبط" />
                      }
                      tag="Link"
                      item={episode}
                      standalone
                    />
                  ))}
                </>
              )}
              {posts.length > 0 &&
                // 4 is the number of cards in a row at desktop mode
                posts.slice(0, 4 - episodes.length).map((item, index) => {
                  return (
                    <PostCard
                      className="post-card"
                      tagComponent={<Tag color="white" children="نوشته بلاگ" />}
                      standalone
                      tag="Link"
                      key={index}
                      item={item}
                      overlayColor="--primary"
                    />
                  )
                })}
            </div>
            {posts.length > 4 - episodes.length && (
              <div className="article__more__action">
                <Link
                  className="navigator"
                  to={`/blog/tag/${slugify(
                    post?.people.map(person => person.shownName).join(" و ")
                  )}`}
                >
                  <Button color="primary-outlined">
                    مشاهده همه نوشته‌های مرتبط با{" "}
                    {post?.people.map(person => person.shownName).join(" و ")}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Section>
      </main>
      <Footer />
      <ActionBar />
    </Container>
  )
}

export const query = graphql`
  query($slug: String!, $people: [Int]!) {
    strapiPost(fields: { slug: { eq: $slug } }) {
      created_at
      title
      content
      resources {
        url
        title
      }
      category {
        name
      }
      people {
        shownName
      }
      author {
        firstName
        lastName
      }
      keyword {
        title
      }
      fields {
        slug
      }
      description
      mainImage {
        childImageSharp {
          fixed {
            src
            width
            height
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fields {
        readingTime
      }
    }
    relatedEpisodes: allStrapiEpisode(
      sort: { fields: releaseDate, order: DESC }
      filter: { people: { elemMatch: { id: { in: $people } } } }
      limit: 5
    ) {
      edges {
        node {
          mainImage {
            childImageSharp {
              fixed {
                height
                src
                width
              }
              fluid {
                src
              }
            }
          }
          releaseDate
          title
          episodeNumber
          fields {
            slug
          }
        }
      }
    }
    relatedPosts: allStrapiPost(
      sort: { fields: created_at, order: DESC }
      filter: { people: { elemMatch: { id: { in: $people } } } }
      limit: 4
    ) {
      edges {
        node {
          title
          created_at
          fields {
            slug
          }
          mainImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
//

export default BlogPost
